import Vue from 'vue';
import {PROVIDED_ASSISTANCE_OPTIONS_VALUES} from "../../../../config";
import {getStepColor} from "../../../common";
import GoalTaskResource from '~/angular/app/projects/project_task_resource';

export default {
    props: {
        allowSetAssistanceOptions: Boolean,


        step: Object,
        completable: Boolean,
        taskId: Number,

    },
    computed: {
        isCompletedButNotAchieved() {
            return this.step.completed && this.step.provided_assistance && this.step.provided_assistance.map(a => a.code).includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED);
        },
        stepColor() {
            return getStepColor(this.step);
        },
        stepStyle() {
            return {
                color: this.stepColor
            };
        }
    },
    methods: {
        markStepAsCompleted(step, withSupport, providedAssistance = []) {
            this.$loadingSpinner.show();
            GoalTaskResource().mark_step_as_completed(this.taskId, step.id, withSupport, providedAssistance)
                .then(resp => {
                    step.completed = resp.data.step.completed;
                    step.completion_type = resp.data.step.completion_type;
                    step.completed_by = resp.data.step.completed_by;

                    step.provided_assistance = resp.data.step.provided_assistance;
                    if (resp.data.task.completed) {
                        this.$emit('taskCompleted', resp.data);
                    }
                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.notifications.error(err.data);
                })
                .finally(()=>this.$loadingSpinner.hide());
        },
        markStepAsInCompleted(step) {
            this.$loadingSpinner.show();
            GoalTaskResource().mark_step_as_incompleted(this.taskId, step.id)
                .then(resp => {
                    step.completed = resp.data.step.completed;
                    step.completion_type = resp.data.step.completion_type;
                    step.completed_by = resp.data.step.completed_by;
                    step.provided_assistance = resp.data.step.provided_assistance;

                    this.$emit('taskInCompleted', resp.data);
                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.notifications.error(err.data);
                })
                .finally(()=>this.$loadingSpinner.hide());
        },
        updateCompletionOptions(step, withSupport, providedAssistance = []) {
            this.$loadingSpinner.show();
            GoalTaskResource().update_step_completion_params(this.taskId, this.step.id, withSupport, withSupport ? providedAssistance : undefined)
                .then(resp => {
                    step.completed = resp.data.step.completed;
                    step.completion_type = resp.data.step.completion_type;
                    step.completed_by = resp.data.step.completed_by;
                    step.provided_assistance = resp.data.step.provided_assistance;

                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(()=>this.$loadingSpinner.hide());
        },
        toggleCompletedStatus(step) {
            if (!this.completable) {
                return;
            }
            step.completed = !step.completed;
            if (step.completed) {
                this.markStepAsCompleted(step);
            } else {
                this.markStepAsInCompleted(step);
            }
        },


        showCompletionOptions() {
            this.$refs['complete-dropdown'].show(false, this.step.provided_assistance, this.availableCompletionOptions)
                .then(({result, withSupport, options}) => {
                    this.markStepAsCompleted(this.step, withSupport, options);
                });
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs['complete-dropdown']?.focusOnFirstOption();
                }, 50);
            });
        },
        showIncompletionOptions() {
            this.$refs['complete-dropdown'].show(true, this.step.provided_assistance, this.availableCompletionOptions)
                .then(({result, withSupport, options}) => {
                    if (result === 'complete') {
                        this.updateCompletionOptions(this.step, withSupport, options);
                    } else {
                        this.markStepAsInCompleted(this.step);
                    }
                });
        },

        onCompleteCheckboxClicked() {
            if (this.allowSetAssistanceOptions) {
                if (this.step.completed) {
                    this.showIncompletionOptions();
                } else {
                    this.showCompletionOptions();
                }

            } else {
                this.toggleCompletedStatus(this.step);
            }
        },
    },
    mounted() {
    }
};
